import { Button, Drawer, DrawerBody, DrawerContent, Stack, useDisclosure } from '@chakra-ui/react'
import { CustomCollapse } from './Collapse'
import { ToggleButton } from './ToggleButton'
import { useNavigate } from 'react-router-dom';
import { MENS_POPOVER, WOMENS_POPOVER } from './menu-data';
import LoginButton from "../Auth/LoginButton";
import LoginButton2 from "../Auth/LoginButton2";

export const MobileDrawer = () => {
  const navigate = useNavigate();
  const { isOpen, onToggle, onClose } = useDisclosure()

  const handleNavigation = (path: string) => {
    // Close the drawer
    onClose();
    // Navigate to the new path
    if (/^(https?:\/\/|mailto:)/.test(path)) {
      // External URL - navigate in current tab or open a new tab
      window.location.href = path; // or `window.open(path, '_blank')` for new tab
    } else {
      // Internal route - use navigate
      navigate(path);
    }
  }

  return (
    <>
      <ToggleButton
        isOpen={isOpen}
        onClick={onToggle}
        aria-label="Open menu"
        display={{ base: 'inline-flex', lg: 'none' }}
      />
      <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
        <DrawerContent>
          <DrawerBody mt="72px" p="4">
            <Stack spacing="1">
              <Button onClick={() => handleNavigation("/")} size="lg" variant="tertiary" justifyContent="start" >
                Home
              </Button>
              <Button onClick={() => handleNavigation("/schedules")} size="lg" variant="tertiary" justifyContent="start" >
                Schedules
              </Button>
              <Button onClick={() => handleNavigation("/standings")} size="lg" variant="tertiary" justifyContent="start">
                Standings
              </Button>
              <CustomCollapse menuContent={WOMENS_POPOVER} title="Women's" closeDrawer={onClose} />
              <CustomCollapse menuContent={MENS_POPOVER} title="Men's" closeDrawer={onClose} />
              <Button onClick={() => handleNavigation("mailto:info@masshshockey.com")} size="lg" variant="tertiary" justifyContent="start" width="full">
                Contact
              </Button>
              <LoginButton2 size="large" variant="tertiary" justifyContent="start" class="css-1sn3vsn">
                Login
              </LoginButton2>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
