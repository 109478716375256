import React, {CSSProperties} from 'react';
import {
    Box, Flex, Heading, theme,
} from '@chakra-ui/react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TeamSearchDropdown from "../components/EndUser/Search/TeamSearchDropdown";
import TeamListPage from './TeamsPage';
import UpcomingGames from "./components/UpcomingGames";
import TodaysGames from "./components/TodaysGames";

const carouselImages = [
    "https://storage.googleapis.com/masshshockey2/images/carousel/BC-High_featured.jpg",
    "https://storage.googleapis.com/masshshockey2/images/carousel/2018-19_Mens-D1_Duxbury.jpg",
    "https://storage.googleapis.com/masshshockey2/images/carousel/2018-19_Mens-D2_Canton.jpg",
    "https://storage.googleapis.com/masshshockey2/images/carousel/2018-19_Mens-D3_Wachusett.jpg",
    "https://storage.googleapis.com/masshshockey2/images/carousel/Girls-D1-Final_Methuen-Tewksbury-vs-Austin-Prep-3276.jpg",
    "https://storage.googleapis.com/masshshockey2/images/carousel/2018-19_Womens-D2_Wellesley.jpg",
    "https://storage.googleapis.com/masshshockey2/images/carousel/Girls-D1-Final_Methuen-Tewksbury-vs-Austin-Prep-3276.jpg",
    "https://storage.googleapis.com/masshshockey2/images/carousel/2018-19_Womens-D2_Wellesley.jpg",
]


const HomePage: React.FC = () => {
    const heroSectionStyle = {
        height: '50vh',
        maxHeight: '100%',
        minHeight: '250px',
        backgroundImage: `url(${carouselImages[Math.floor(Math.random() * carouselImages.length)]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        _before: {
            content: '" "',
            position: 'absolute',
            inset: '0 0 0 0',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 0,
            pointerEvents: 'none'
        }
    };

    return (
        <>
            <Flex position="relative" {...heroSectionStyle} >
                <Box textAlign="center">
                    <Heading position="relative" zIndex={1} mb={2} textAlign="center" color={theme.colors.white}>Team Search</Heading>
                </Box>
            </Flex>
            <TodaysGames />
            <UpcomingGames />
        </>
    );
};

export default HomePage;
