import React, { useState, FormEvent } from 'react';
import { useUser } from '../User/UserContext';
import {
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword
} from 'firebase/auth';
import {
    Button as ChakraButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl,
    FormLabel, Input, useDisclosure, useToast, theme
} from '@chakra-ui/react';
import { apiCallNoAuth } from "../../utils/apiUtility";
import { Client, Organization } from "../../utils/apiTypes";
import { useClient } from "../Contexts/ClientContext";
import { useOrganization } from "../Contexts/OrganizationContext";
import { Link, useNavigate } from "react-router-dom";

interface LoginButtonProps {
    variant?: string;
    colorScheme?: string;
    size?: string;
    [key: string]: any; // To allow passing other ChakraButton props
}

const LoginButton: React.FC<LoginButtonProps> = ({ variant, colorScheme, size, ...props }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const auth = getAuth();
    const toast = useToast();
    const navigate = useNavigate();

    const { setRole } = useUser();
    const { client, setClient } = useClient();
    const { organization, setOrganization } = useOrganization();

    onAuthStateChanged(auth, (user) => {
        if (user) {
            console.log('Logged in User: ', user);
        } else {
            console.log('No user is signed in.');
        }
    });

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            const client = await apiCallNoAuth<Client>('/api/v1/auth/client', 'POST', { "email": email, "password": password });
            auth.tenantId = client.tenantId;

            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const idTokenResult = await user.getIdTokenResult(true); // Refresh the token to get latest claims
            const claims = idTokenResult.claims;
            const userRole = claims.role as string;
            const organizationId = claims.organization as string | undefined;

            console.log('User role:', userRole);
            console.log('Organization ID:', organizationId);

            setRole(userRole);
            setClient(client);
            localStorage.setItem('userRole', userRole);

            if (userRole === 'SuperAdmin') {
                navigate('/admin');
            } else if (userRole === 'Admin') {
                navigate('/client');
            } else if (userRole === 'OrganizationAdmin' && organizationId) {
                try {
                    const organization = await apiCallNoAuth<Organization>(`/api/v1/admin/organizations/${organizationId}`, 'GET');
                    setOrganization(organization);
                    navigate('/organization/dashboard');
                } catch (orgError) {
                    console.error("Error fetching organization:", orgError);
                    toast({
                        title: "Organization Fetch Failed",
                        description: "Could not retrieve organization information",
                        status: "error",
                        duration: 1500,
                        isClosable: true,
                    });
                }
            } else {
                navigate('/');
            }

            toast({
                title: "Logged In",
                description: "You have successfully logged in",
                status: "success",
                duration: 1500,
                isClosable: true,
            });
        } catch (error) {
            if (error instanceof Error) {
                toast({
                    title: "Login Attempt Failed",
                    description: "Login Failed. For issues, please contact info@masshshockey.com",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                console.error("Unexpected error:", error);
            }
        }
    };

    return (
        <>
            <Link to="/" onClick={onOpen}>
                <ChakraButton
                    variant={variant || "outline"}
                    colorScheme={colorScheme || "blue"}
                    size={size || "md"}
                    {...props} // Spread any additional props passed to the LoginButton
                >
                    Login
                </ChakraButton>
            </Link>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Login</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleSubmit}>
                            <FormControl id="email">
                                <FormLabel>Email</FormLabel>
                                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </FormControl>

                            <FormControl id="password">
                                <FormLabel>Password</FormLabel>
                                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </FormControl>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <ChakraButton variant="ghost" mr={3} onClick={handleSubmit}>
                            Login
                        </ChakraButton>
                        <ChakraButton variant="ghost" onClick={onClose}>
                            Cancel
                        </ChakraButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default LoginButton;
