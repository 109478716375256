import React from 'react';
import { Box, SimpleGrid, Heading } from '@chakra-ui/react';
import TeamScheduleCard from './TeamScheduleCard';
import useApi from '../hooks/useApi';
import { useSeasonYear } from "../hooks/SeasonYearContext";
import { format, addDays } from 'date-fns';
import {Game} from "../../utils/apiTypes";

const UpcomingGames: React.FC = () => {
    const { gender, selectedYear, getSeasonId } = useSeasonYear();
    const seasonId = getSeasonId(selectedYear, gender);

    const today = format(new Date(), 'yyyy-MM-dd');
    const tenDaysLater = format(addDays(new Date(), 10), 'yyyy-MM-dd');

    const { data: games, loading } = useApi<Game[]>(`api/v1/client/10/games?seasonId=${seasonId}&gender=${gender}&filterStartDate=${today}&filterEndDate=${tenDaysLater}`);

    if (loading) {
        return <Box>Loading...</Box>;
    }

    if (!games || games.length === 0) {
        return <Box>No upcoming games scheduled.</Box>;
    }

    return (
        <Box>
            <Heading as="h2" size="lg" mb={4}>Upcoming Games</Heading>
            <SimpleGrid columns={1} spacing={4}>
                {games.map((game: any, index: number) => (
                    <TeamScheduleCard
                        key={index}
                        gameDate={game.date}
                        gameTime={game.time}
                        location={game.location}
                        team1Name={game.homeTeam}
                        team1Id={game.homeTeamId}
                        team1Score={game.homeTeamScore || 0}
                        team2Name={game.awayTeam}
                        team2Id={game.awayTeamId}
                        team2Score={game.awayTeamScore || 0}
                        status={game.status}
                        notes={game.notes}
                        gender={gender}
                    />
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default UpcomingGames;
