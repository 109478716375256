import React from 'react';
import { Box, Grid } from '@chakra-ui/react';
import TeamSchedule from './components/TeamSchedule';
import {useSeasonYear} from "./hooks/SeasonYearContext";


const SchedulePage: React.FC = () => {
    // Access gender and selectedYear (season) from context
    const { gender, selectedYear } = useSeasonYear();

    return (
        <Grid
            templateColumns={["1fr", "1fr 3fr 1fr"]}
            gap={6}
        >
            <Box gridColumn={["1 / -1", "1 / 2"]} p={4} order={[1, 0]}>
                <a href="https://hnibonline.com?utm_source=masshshockey&utm_medium=web-ad&utm_campaign=mhsh-web"
                   target="_blank" rel="noopener noreferrer" style={{ margin: '10px' }}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/HNIB_ad.jpg"
                         alt="HNIB Online" />
                </a>
                <a href="http://dynamiksports.com/" target="_blank" rel="noopener noreferrer"
                   style={{ margin: '10px', verticalAlign: 'top' }}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/Dynamik-300x250.jpg"
                         alt="Dynamic Sports 2" />
                </a>
            </Box>
            <Box gridColumn={["1 / -1", "2 / 3"]} p={4} order={[0, 0]}>
                {/* Pass gender and season to TeamSchedule */}
                <TeamSchedule  />
            </Box>
            <Box gridColumn={["1 / -1", "3 / 4"]} p={4} order={[1, 0]}>
                <a href="https://laurastamm.com/" target="_blank" rel="noopener noreferrer"
                   style={{ margin: '10px', verticalAlign: 'top' }}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/Laura-Stamm_2021_300px.jpg"
                         alt="Laura Stamm" />
                </a>
                <a href="https://nswingsgirls.com/" target="_blank" rel="noopener noreferrer"
                   style={{ margin: '10px', verticalAlign: 'top' }}>
                    <img width="300" height="300"
                         src="https://storage.googleapis.com/masshshockey2/images/ads/NS_WINGS_5_AD.jpg"
                         alt="NS Wings" />
                </a>
            </Box>
        </Grid>
    );
};

export default SchedulePage;
